export const environment = {
    apiBaseUrl: 'API_BASE_URL',
    pageTitle: 'Zionex - Hospedagem de Ativos Digitais',
    isCapacitor:false,
    mockExchange: false,
    tokenIdWall: '',
    projectName: 'Zionex',
    firstRoute: '/home',
    graphUrl: '',
    showGraph: false,
    pixelId: '',
    BankingCheckout: false,
    baseUrl: 'zionex.com.br',
    showAccountImage: false,
    collectionId: 'b8087b4c-5b9d-4bb1-acb6-83a917b92444',
    tokenExchange: 'BRZ',
    oneSignalId: '',
    isKrakenIntegrationEnabled: false,
    signupExternal: false,
    signupURL: '',
    WS_ENDPOINT: "",
    decimalPlaces: '1.6-6',
    decimalsPlacesBought: 6,
    mainRoute: 'account/login',
    showHeaderBuy: false,
    billNameCompany:"Zionex",
    companyName: 'Zionex',
    cnpj: '55.932.468/0001-06',
    waterMark: false,
    webService: 'wss://websocket.zionex.com.br',
    priority: 'light'
};